@font-face {
  font-family: 'Lato';
  src: url(./Lato-LightItalic.woff2) format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('Lato-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('Lato-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('Lato-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('Lato-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('Lato-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('Lato-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('Lato-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
