.end-game-view {
  overflow: hidden;
  margin: 5px;
  margin-bottom: 0px;
}
.end-game-view-content {
  padding: 15px;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: rgb(215, 215, 215);
}
.end-game-minimize-button {
  float: right;
}
.end-game-view.minimize {
  height: 120px;
  overflow: hidden;
}
.end-game-return-wrapper {
  margin: 15px;
}
.end-game-winners {
  margin: 5px;
}
.end-game-return {
  text-decoration: none;
  color: black;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.end-game-title {
  display: block;
  color: rgb(235, 235, 235);
  font-size: 20px;
  background-color: rgb(55, 55, 55);
  text-align: center;
}
.end-game-title-top {
  padding: 7px;
  border-bottom: 1px solid rgb(85, 85, 85);
}
.end-game-title-bottom {
  padding: 7px;
}
.disclaimer {
  text-align: center;
  margin: 10px;
  font-size: 15px;
  width: 100%;
  display: block;
}
.graphs-cardtypes-pie {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 5px;
}

/* TODO(MAX): make class selectors for d3 svgs more specific or im fucked*/
.axis path,
.axis line {
  fill: none;
  stroke: grey;
  stroke-width: 1;
  shape-rendering: crispEdges;
}
.domain {
  fill: none;
}
.tick line {
  display: none;
}

.axis path,
.axis line {
  fill: none;
  stroke: grey;
  stroke-width: 1;
  shape-rendering: crispEdges;
}

.x.axis path {
}

.line {
  fill: none;
  stroke: steelblue;
  stroke-width: 2px;
}

.circle {
  fill: white;
  stroke: steelblue;
  stroke-width: 2px;
}

.hover-line {
  stroke: #838383;
}

.chart-tooltip {
  text-align: left;
  line-height: 1.5;
  visibility: hidden;
  width: 120px;
  padding: 5px;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 13px;
  text-align: left;
  pointer-events: none;
  z-index: 1060;
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
}

.chart-tooltip > h4 {
  font-size: 16px;
}

.chart-tooltip > p {
  padding: 10px 0;
  font-weight: 400;
}

.chart-tooltip > p > span {
  float: right;
}

.chart-tooltip > p > span > em {
  font-size: 85%;
}

.chart-tooltip > p:last-child {
  padding-bottom: 0;
}
.chart-tooltip > ul {
  text-align: left;
}

.graphs-cardtypes-pie h4 {
  text-align: center;
  font-size: 12px;
}
.graphs-favorgained-line {
  font-size: 8px;
  display: flex;
  width: 100%;
  justify-content: center;
  padding-right: 5px;
}
.graphs-favorgained-line h4 {
  font-size: 14px;
  text-align: center;
  padding-top: 3px;
  margin-bottom: -7px;
}
.graphs-favorgained-line path {
  stroke-width: 2;
  fill: none;
}
.player-turn-table {
  width: 100%;
  border-right: 1px solid rgba(125, 125, 125, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.graphs-title {
  font-size: 14px;
  text-align: center;
  padding: 2px;
  margin-bottom: 4px;
  margin-top: 16px;
  margin-left: 104px;
}
.player-turn-table col {
  border-left: 1px solid rgba(125, 125, 125, 0.1);
}
.player-turn-table col:last-child {
  border-right: 1px solid rgba(125, 125, 125, 0.1);
}
.player-turn-table col:first-child {
  width: 50px;
}
.player-turn-table td,
.player-turn-table th {
  width: 24px;
  height: 24px;
  font-size: 9px;
  text-align: center;
  vertical-align: middle;
}
.player-turn-table th.turn_1 {
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  background-color: rgba(125, 125, 125, 0.07);
}
.card-stats-table td {
  border-bottom: 1px solid rgba(200, 200, 200, 0.9);
  border-left: 1px solid rgba(200, 200, 200, 0.2);
}
.card-stats-table tr:last-child > td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.player-turn-table tr:nth-child(2n) {
  background-color: rgba(125, 125, 125, 0.1);
}
.montecarlo-table td.W {
  background-color: rgb(50, 210, 50);
  background-color: rgb(242, 115, 40);
}
.montecarlo-table td.W_plus {
  background-color: rgb(40, 165, 40);
  background-color: rgb(200, 85, 35);
}
.montecarlo-table td.W_minus {
  background-color: rgb(65, 240, 65);
  background-color: rgb(255, 145, 40);
}
.montecarlo-table td.L {
  background-color: rgb(210, 40, 40);
  background-color: rgb(80, 145, 210);
}
.montecarlo-table td.L_plus {
  background-color: rgb(165, 30, 30);
  background-color: rgb(85, 85, 150);
}
.montecarlo-table td.L_minus {
  background-color: rgb(240, 65, 65);
  background-color: rgb(75, 115, 180);
}
.montecarlo-table td.X {
  background-color: rgba(125, 125, 125, 0.1);
}

/* Hack for D275 - Remove when making line charts dynamic width (15 lines)*/
.end-game-view {
  background-color: rgb(215, 215, 215);
}
.end-game-view-content {
  width: 751px;
  margin: auto;
}
.player-turn-table col:first-child {
  width: 100px;
  min-width: 100px;
}
.stats-table-include-turn0 col:first-child {
  width: 76px;
  min-width: 76px;
}
.graphs-favorgained-line,
.graphs-cardtypes-pie {
  padding-left: 52px;
}

.line-chart-tab-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #b8b8b8;
}

.line-chart-tab-buttons {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  align-self: stretch;
}

.line-chart-tab-buttons button {
  background-color: #d8d8d8;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
  padding: 5px 10px;
  cursor: pointer;
}

.line-chart-tab-buttons button.selected {
  background-color: #b8b8b8;
}
